import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import styled from "styled-components";
import media from "styled-media-query";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import Facilities from "../../components/Facilities";

import MainImage1 from "../../img/kado-3.jpg";
import MainImage2 from "../../img/kado-4.jpg";
import MainImage3 from "../../img/kado-5.jpg";
import BgImage1 from "../../img/news-bg.png";

const RoungeKadoPage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return (
    <Layout>
      {/*
      パンくず
      メインビジュアル
      タイトル
      2カラム紹介
      2カラムボタン
      詳細施設案内
      Googleマップ埋め込み
      共通の施設案内
      パンくず
      */}
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="奉還町4丁目 ラウンジ・カド"  />
      <ImageBig src={MainImage1}></ImageBig>
      <MidashiBg>
        <Komidashi>奉還町4丁目</Komidashi>
        <Midashi>ラウンジ・カド</Midashi>
      </MidashiBg>
      <p>
      街を眺めながら、食べたり、飲んだり、見たり、話したり、歌ったり。<br />
      飲食とイベントを楽しむ街角のラウンジです。<br />
      おおらかに、みんなの興味関心をもちよりながら、<br />
      地域の出来事や風景を発見・発信・まなびあっていく文化施設でもあります。
      </p>
      <Intro2ColumnDiv>
          <Column>
              <ImageSmall src={MainImage2}></ImageSmall>
              <Komidashi>地域に根ざし、おおらかにひらく</Komidashi>
              <p>ラウンジ・カドは、岡山県内外で地域に根ざして活動するユニークな人々やつくり手とのつながりを生かしたメニュー、イベント企画をメインに運営をしています。企画・活動でカドを使ってみたい方はお気軽にお問い合わせください。2階にはNAWATEやカドの改修を手がけたココロエ一級建築士事務所が入居しています。</p>
          </Column>
          <Column>
              <Komidashi>酒とつまみで小粋に楽しむ</Komidashi>
              <p>ラウンジ・カドの週末オープンを「カドびらき」と呼んでいます。お酒やつまみ、ノンアルのドリンクで人と喋る人、家でなかなかすすまない読書をする人。あるときは映像や音楽が流れ、素敵なものを販売している日も。いつ来てもちょっとした変化が楽しめる、週末の憩いの場です。</p>
                 <br />
                 <br />
              <ImageSmall src={MainImage3}></ImageSmall>
          </Column>
      </Intro2ColumnDiv>
      <Button2ColumnDiv>
          <FlexA href="https://www.lounge-kado.jp/" target="_blank">
          <Button color="#666666" color2="#ffffff" words="カドびらき・イベントの開催日"></Button>
          </FlexA>
          <FlexA href="https://www.instagram.com/lounge_kado/" target="_blank">
          <Button color="#666666" color2="#ffffff" words="場所を借りる"></Button>
          </FlexA>
      </Button2ColumnDiv>
      <DetailDiv>
          <Nakamidashi>奉還町4丁目 ラウンジ・カド</Nakamidashi>
          <br />
          <p>
            〒700-0026　岡山市北区奉還町4丁目 7-15<br />
            mail : 3355.nawate[@]gmail.com <br />
            TEL : 086-236-8326 <br />
            金 17:00-21:00<br />
            土・日 13:00-21:00<br />
            https://www.lounge-kado.jp
          </p>
      </DetailDiv>
      <iframe src="https://www.google.com/maps?output=embed&z=16&ll=34.6689325,133.9082861&q=ラウンジ・カド" width="100%" height="300px" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      <br />
      </Body>
      <Facilities />
      <FooterDiv>
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="奉還町4丁目 ラウンジ・カド"  />
      </FooterDiv>
    </Layout>
  );
}

export default RoungeKadoPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  
`;

const MidashiBg = styled.div`
  width: 100%;
  background-image: url(${BgImage1});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 8em;
    margin: 0 0 2em 0;
    padding: 2em 15vw 0 15vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 15em;
    margin: 0 0 5em 0;
    padding: 5em 15vw 0 15vw;
  `}
`;

const Intro2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 5vh 0 0 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12vh 0 0 0;
  `}
`;

const Button2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 0 auto 0 auto;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 5vh auto 0 auto;
  `}
`;

const FlexA = styled.a`
  display: flex;
`;

const DetailDiv = styled.div`
  height: 32vh;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto 5vh auto;
  padding: 3vh 3vw 3vh 3vw;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 50%;
  `}
`;

const FooterDiv = styled.div`
  margin: 3vh 5vw 3vh 5vw;
`;

const Column = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 0 0 10vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 40vw;
  `}
`;

const ImageBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 80vh;
  `}
`;

const ImageSmall = styled.img`
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
    margin: 0 0 3vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 50vh;
    margin: 0 0 10vh 0;
  `}
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 20pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 28pt;
  `}
`;

const Komidashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 14pt;
    margin: 0 0 1vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 18pt;
    margin: 0 0 5vh 0;
  `}
`;

const Nakamidashi = styled.p`
font-family: 'Noto Sans JP', sans-serif;
font-weight: 700;
font-size: 14pt;
text-align: center;
`;
